@import url('https://fonts.googleapis.com/css?family=Barlow:400,500,600,700|DM+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:400;500;600;700&display=swap');
body,
input,
button {
  font-family: 'Barlow', 'Helvetica', 'Arial', sans-serif;
}
body {
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif;
  color: #030327;
}
h1 {
  line-height: 73px;
}
#contentArea {
  max-width: none;
  max-width: initial;
  padding-bottom: 0;
  padding-right: 0px;
  padding-left: 0px;
}
li ul {
  padding-top: 8px;
  padding-left: 16px;
}
header {
  position: sticky;
  top: 0;
  z-index: 99999;
  box-shadow: 0 10px 15px rgba(228, 236, 236, 0.8);
}
header .navbar-inner {
  height: 56px;
  padding: 0;
}
header.navbar .brand {
  padding: 0 20px;
}
header .navbar-inner .container-fluid {
  display: flex;
  align-items: center;
  max-width: none;
  max-width: initial;
  height: 100%;
}
header .navbar-inner .brand img {
  max-width: 150px;
  padding: 0 0 0 20px;
}
header .navbar-inner .nav {
  display: flex;
  height: 100%;
  margin: 0;
}
header .navbar-inner .nav li {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-left: 1px solid #e7ebee;
}
header .navbar-inner .nav li.active a {
  font-weight: 500;
  border-bottom: 3px solid #00759e;
}
header .navbar-inner .nav li.active .caret {
  display: none;
}
header .navbar-inner .nav li:hover {
  cursor: pointer;
}
header .navbar-inner .nav li a {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 30px;
  border-bottom: 3px solid transparent;
}
header .navbar-inner .nav li:hover a {
  border-bottom: 3px solid #00759e;
  font-weight: 500 !important;
}
@media (max-width: 1000px) {
  .nav-collapse.collapse nav {
    display: none !important;
  }

  .nav-collapse.collapse.in nav {
    display: block !important;
    border-top: 1px solid #e7ebee;
    box-shadow: 0 2px 65px rgba(0, 82, 111, 0.15);
  }

  .nav-collapse.collapse {
    position: absolute !important;
    top: 56px;
    z-index: 9999;
    width: 100vw;
    height: auto;
    overflow: visible;
    background: #fff;
  }

  header .navbar-inner .btn-navbar {
    border: 0;
    background: 0 0;
    background-color: #fff !important;
    box-shadow: none;
    display: block !important;
    outline: 0 !important;
  }

  .navbar .nav {
    position: relative !important;
    float: none !important;
  }

  .nav-collapse.collapse.in nav .nav {
    position: relative !important;
    float: none !important; 
    flex-direction: column;
  }

  header .navbar-inner .nav li {
    height: 56px;
    float: none;
  }
  
  header .navbar-inner .nav li a {
    width: 100%;
  }

  header .navbar-inner .btn-navbar .icon-bar {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    margin: 0;
    background: #0a0836;
    border-radius: 2px;
    box-shadow: none;
    opacity: 1;
    transition: 0.25s ease-in-out;
  }

  header .navbar-inner .btn-navbar .icon-bar:nth-child(2) {
    top: 0;
  }

  header .navbar-inner .btn-navbar .icon-bar:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
  }

  header .navbar-inner .btn-navbar .icon-bar:nth-child(4) {
    bottom: 0;
  } 

  header .navbar-inner .btn-navbar {
    position: relative;
    right: 20px;
    width: 22px;
    height: 18px;
    padding: 0;
    margin: 0 0 0 auto;
  } 
}
@media (max-width: 768px) {
  header .navbar-inner {
    height: 56px;
  }
  header .navbar-inner .btn-navbar {
    position: relative;
    right: 20px;
    width: 22px;
    height: 18px;
    padding: 0;
    margin: 0 0 0 auto;
  }
  header .navbar-inner .btn-navbar .icon-bar {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    margin: 0;
    background: #0a0836;
    border-radius: 2px;
    box-shadow: none;
    opacity: 1;
    transition: 0.25s ease-in-out;
  }
  header .navbar-inner .btn-navbar:hover .icon-bar {
    background: #0a0836;
  }
  header .navbar-inner .btn-navbar .icon-bar:nth-child(2) {
    top: 0;
  }
  header .navbar-inner .btn-navbar .icon-bar:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
  }
  header .navbar-inner .btn-navbar .icon-bar:nth-child(4) {
    bottom: 0;
  }
  .nav-collapse.collapse {
    position: absolute;
    top: 56px;
    z-index: 9999;
    width: 100vw;
    height: auto;
    overflow: visible;
    background: #fff;
  }
  .nav-collapse.collapse nav {
    display: none;
  }
  .nav-collapse.collapse.in nav {
    display: block;
    border-top: 1px solid #e7ebee;
    box-shadow: 0 2px 65px rgba(0, 82, 111, 0.15);
  }
  .nav-collapse.collapse.in nav .nav {
    flex-direction: column;
  }
  header .navbar-inner .nav li {
    height: 56px;
  }
  header .navbar-inner .nav li a {
    width: 100%;
    padding: 0 10px 0 56px;
  }
}
footer {
  position: relative;
  z-index: 10;
  padding: 40px 32px 16px;
  color: #030327;
  background: #ffffff;
  font-family: 'Inter', sans-serif;
}
@media (max-width: 768px) {
  footer {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    box-sizing: border-box;
  }
}
footer p:first-child {
  display: none;
}
.footer-wrapper {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
}
.footer-content {
  flex: 1 1 auto;
}
.footer-navigation {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
.footer-socials {
  padding: 16px 0;
  margin-top: 16px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px 24px;
  border-top: 1px solid rgba(195, 205, 210, 0.4);
  border-bottom: 1px solid rgba(195, 205, 210, 0.4);
  flex-wrap: wrap;
}
.footer-social-link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #605E5D;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.footer-social-link:hover {
  color: #605E5D;
  opacity: 0.8;
  text-decoration: none;
}
.footer-social-link:visited {
  text-decoration: none;
}
@media (max-width: 1188px) {
  .footer-social-link span {
    display: none;
  }
  .footer-social-link:last-child {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-social-link:last-child img {
    display: none;
  }
}
.footer-copyright {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px 60px;
}
@media (max-width: 1188px) {
  .footer-copyright {
    margin-top: 32px;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
}
.footer-copyright__box {
  text-align: center;
}
.footer-logo {
  flex: 0 0 auto;
}
.footer-logo img {
  width: 58px;
}
.footer-product {
  margin-bottom: 8px;
  font-size: 12px;
}
@media (max-width: 1188px) {
  .footer-product {
    margin-bottom: 4px;
  }
}
.footer-product a {
  text-decoration: none;
}
.footer-company {
  font-size: 12px;
}
.footer-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  max-width: 300px;
  box-sizing: border-box;
}
.footer-column:first-child {
  max-width: 180px;
}
.footer-column:last-child {
  max-width: 228px;
}
.footer-column__title {
  margin: 0 0 16px;
  font-size: 16px;
  font-weight: 600;
  color: #030327;
  letter-spacing: 0;
  opacity: 0.8;
}
@media (max-width: 1188px) {
  .footer-column__title {
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
            user-select: none;
  }
  .footer-column__title::after {
    display: block;
    content: '';
    background: url('/images/helpscout/chevron-down.svg') no-repeat;
    margin-left: 4px;
    width: 24px;
    height: 24px;
    filter: invert(8%) sepia(14%) saturate(4954%) hue-rotate(210deg) brightness(94%) contrast(113%);
    transition: 0.25s ease-in-out;
  }
  .footer-column__title.expanded::after {
    transform: rotate(180deg);
  }
}
.footer-column__expance {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  color: #009bcd;
  line-height: 1.5;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.footer-column__expance.hidden {
  display: none;
}
.footer-column__expance:hover {
  opacity: 0.8;
}
.footer-column__expance::after {
  display: block;
  content: '';
  background: url('/images/helpscout/chevron-down.svg') no-repeat;
  margin-left: 4px;
  width: 24px;
  height: 24px;
  filter: invert(46%) sepia(43%) saturate(6811%) hue-rotate(169deg) brightness(101%) contrast(101%);
  transition: 0.25s ease-in-out;
}
.footer-column__expanced.hidden {
  display: none;
}
@media (max-width: 1188px) {
  .footer-column__box {
    max-height: 0;
    overflow: hidden;
  }
}
.footer-column ul {
  margin: 0;
  list-style: none;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.footer-column li {
  padding: 0 0 16px;
  line-height: 1.5;
}
.footer-column a {
  font-size: 16px;
  color: #605E5D;
  text-decoration: none;
}
.footer-column a:hover {
  opacity: 0.5;
}
.footer-column:last-child {
  padding-right: 0;
}
.footer-reward__wrap {
  padding-bottom: 0;
}
.footer-reward {
  margin-left: auto;
  display: flex;
}
.footer-mobile-reward {
  display: none;
}
@media (max-width: 1271px) and (min-width: 1188px) {
  .footer-column__title,
  .footer-column a,
  .footer-column__expance {
    font-size: 14px;
  }
}
@media (max-width: 1188px) {
  .footer {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .footer-logo {
    display: none;
  }
  .footer-navigation {
    flex-wrap: wrap;
  }
  .footer-column {
    max-width: 100%;
    min-width: 50%;
    margin-bottom: 24px;
  }
  .footer-column:nth-child(2n) {
    padding-right: 0;
    padding-left: 20px;
  }
  .footer-column:nth-child(2n-1) {
    padding-right: 20px;
  }
  .footer-column a {
    font-weight: 400;
  }
  .footer-mobile-reward {
    display: block;
  }
  .footer-reward {
    display: none;
  }
  .footer-company {
    order: 1;
    text-align: center;
  }
  .footer-product {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
@media (max-width: 720px) {
  .footer-navigation {
    flex-direction: column;
  }
  .footer-column:nth-child(2n),
  .footer-column:nth-child(2n-1) {
    padding-right: 0;
    padding-left: 0;
  }
  .footer-column:last-child {
    margin-bottom: 0;
  }
}
#docsSearch {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 320px;
  padding: 0 20px;
  margin: 0;
  background-color: #eaf5f9;
  background-image: url('/images/helpscout/hero-bg.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  border-bottom: 1px solid rgba(5, 117, 158, 0.2);
}
#docsSearch h1 {
  padding-bottom: 17px;
  margin: 0;
  font-size: 56px;
  font-weight: bold;
}
@media (max-width: 1150px) {
  #docsSearch {
    background-image: none;
  }
}
@media (max-width: 768px) {
  #docsSearch {
    margin: 0 -20px;
  }
}
.contentWrapper article#fullArticle ul.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: none;
}
.contentWrapper article#fullArticle ul.nav-tabs:before {
  display: none;
}
.contentWrapper article#fullArticle ul.nav-tabs li {
  position: relative;
  min-height: 40px;
  margin: 0;
  padding: 2px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: #05759E;
  border-image: none;
  border-image: initial;
  border-radius: 100px;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
}
.contentWrapper article#fullArticle ul.nav-tabs li::before {
  display: none;
}
.contentWrapper article#fullArticle ul.nav-tabs li.active {
  background-color: #05759E;
}
.contentWrapper article#fullArticle ul.nav-tabs li.active a {
  color: #ffffff;
}
.contentWrapper article#fullArticle ul.nav-tabs li a {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.2;
  color: #05759E;
}
form#searchBar {
  position: relative;
  width: 100%;
  max-width: 480px;
  padding: 0;
  margin: 0 auto;
}
form#searchBar input.search-query {
  height: 60px;
  padding: 0 24px 0 52px;
  border: 0;
  border-radius: 5px;
  box-shadow: none;
}
form#searchBar input.search-query:focus {
  border: 0;
  box-shadow: none;
}
form#searchBar input.search-query::placeholder {
  font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  color: rgba(5, 117, 158, 0.6);
  letter-spacing: -0.02em;
}
form#searchBar button,
form#searchBar.sm button {
  position: absolute;
  top: 50%;
  left: 24px;
  width: 20px;
  height: 20px;
  padding: 0;
  text-indent: -9999px;
  background: none;
  background-image: url('/images/helpscout/icon-magnifier.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0;
  transform: translateY(-50%);
}
form#searchBar button:hover,
form#searchBar.sm button:hover {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  background-image: url('/images/helpscout/icon-magnifier.svg');
}
form#searchBar #serp-dd {
  top: 60px;
  width: 100%;
}
/* This file is generated from SCSS. Do not edit it */
.collection-category:nth-child(odd) {
  position: relative;
  left: 50%;
  width: calc(100vw - 40px);
  padding: 0 20px;
  background-color: #fff;
  transform: translateX(-50%); }
.collection-category h2 {
  padding: 68px 0 37px;
  margin: 0;
  font-size: 48px;
  font-weight: bold;
  line-height: 62px;
  text-align: center; }
.collection-category h2 a {
  color: #030327; }
.collection-category h2 a:hover {
  text-decoration: none;
  border-bottom: 0; }
.collection-category .category-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1080px;
  padding-bottom: 60px;
  margin: 0 auto; }
.collection-category .category {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 335px;
  min-height: 210px;
  padding: 43px 0 36px;
  margin: 0 10px 20px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 0 12px 65px rgba(0, 82, 111, 0.1); }
.collection-category .category:hover {
  border: 1px solid #f8f8f8; }
.collection-category .category:focus {
  text-decoration: none; }
.collection-category .category h3 {
  position: relative;
  margin: 0 auto 10px;
  font-size: 24px;
  font-weight: bold;
  color: #020327; }
.collection-category .category p {
  padding: 0 40px;
  margin: 0 0 5px;
  font-size: 18px;
  line-height: 27px;
  color: #020327; }
.collection-category .category .article-count {
  position: relative;
  margin: auto 0 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px; }
.collection-category .category .article-count::after {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 9px;
  margin-left: 10px;
  content: '';
  background-image: url("/images/helpscout/icon-arrow.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: translateY(-50%); }
.category-list #category-13 h3 {
  padding-left: 35px; }
.category-list #category-13 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/hubspot.svg"); }
.category-list #category-14 h3 {
  padding-left: 35px; }
.category-list #category-14 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/none.svg"); }
.category-list #category-26 h3 {
  padding-left: 35px; }
.category-list #category-26 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-sheets.svg"); }
.category-list #category-27 h3 {
  padding-left: 35px; }
.category-list #category-27 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/pipedrive.svg"); }
.category-list #category-28 h3 {
  padding-left: 35px; }
.category-list #category-28 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/jira.svg"); }
.category-list #category-29 h3 {
  padding-left: 35px; }
.category-list #category-29 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/xero.svg"); }
.category-list #category-30 h3 {
  padding-left: 35px; }
.category-list #category-30 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/quickbooks.svg"); }
.category-list #category-31 h3 {
  padding-left: 35px; }
.category-list #category-31 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/csv.svg"); }
.category-list #category-32 h3 {
  padding-left: 35px; }
.category-list #category-32 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/json.svg"); }
.category-list #category-33 h3 {
  padding-left: 35px; }
.category-list #category-33 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-big-query.svg"); }
.category-list #category-40 h3 {
  padding-left: 35px; }
.category-list #category-40 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/clockify.svg"); }
.category-list #category-94 h3 {
  padding-left: 35px; }
.category-list #category-94 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/wordpress.svg"); }
.category-list #category-116 h3 {
  padding-left: 35px; }
.category-list #category-116 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/shopify.svg"); }
.category-list #category-122 h3 {
  padding-left: 35px; }
.category-list #category-122 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/trello.svg"); }
.category-list #category-124 h3 {
  padding-left: 35px; }
.category-list #category-124 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/none.svg"); }
.category-list #category-148 h3 {
  padding-left: 35px; }
.category-list #category-148 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-sheets.svg"); }
.category-list #category-150 h3 {
  padding-left: 35px; }
.category-list #category-150 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-big-query.svg"); }
.category-list #category-169 h3 {
  padding-left: 35px; }
.category-list #category-169 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/microsoft-excel.svg"); }
.category-list #category-186 h3 {
  padding-left: 35px; }
.category-list #category-186 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-drive.svg"); }
.category-list #category-188 h3 {
  padding-left: 35px; }
.category-list #category-188 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/slack.svg"); }
.category-list #category-189 h3 {
  padding-left: 35px; }
.category-list #category-189 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/airtable.svg"); }
.category-list #category-219 h3 {
  padding-left: 35px; }
.category-list #category-219 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/harvest.svg"); }
.category-list #category-220 h3 {
  padding-left: 35px; }
.category-list #category-220 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/microsoft-excel.svg"); }
.category-list #category-232 h3 {
  padding-left: 35px; }
.category-list #category-232 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/salesforce.svg"); }
.category-list #category-241 h3 {
  padding-left: 35px; }
.category-list #category-241 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/dropbox.svg"); }
.category-list #category-242 h3 {
  padding-left: 35px; }
.category-list #category-242 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/mailchimp.svg"); }
.category-list #category-251 h3 {
  padding-left: 35px; }
.category-list #category-251 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/onedrive.svg"); }
.category-list #category-268 h3 {
  padding-left: 35px; }
.category-list #category-268 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/woocommerce.svg"); }
.category-list #category-244 h3 {
  padding-left: 35px; }
.category-list #category-244 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/facebook.svg"); }
.category-list #category-273 h3 {
  padding-left: 35px; }
.category-list #category-273 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/tableau.svg"); }
.category-list #category-274 h3 {
  padding-left: 35px; }
.category-list #category-274 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/qlik_sense.svg"); }
.category-list #category-276 h3 {
  padding-left: 35px; }
.category-list #category-276 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/asana.svg"); }
.category-list #category-282 h3 {
  padding-left: 35px; }
.category-list #category-282 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/mysql.svg"); }
.category-list #category-290 h3 {
  padding-left: 35px; }
.category-list #category-290 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/googleadv.svg"); }
.category-list #category-296 h3 {
  padding-left: 35px; }
.category-list #category-296 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/linkedin.svg"); }
.category-list #category-307 h3 {
  padding-left: 35px; }
.category-list #category-307 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-analytics.svg"); }
.category-list #category-310 h3 {
  padding-left: 35px; }
.category-list #category-310 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/timetonic.svg"); }
.category-list #category-356 h3 {
  padding-left: 35px; }
.category-list #category-356 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/bamboohr.svg"); }
.category-list #category-348 h3 {
  padding-left: 35px; }
.category-list #category-348 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/clearbit.svg"); }
.category-list #category-327 h3 {
  padding-left: 35px; }
.category-list #category-327 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/coda.svg"); }
.category-list #category-332 h3 {
  padding-left: 35px; }
.category-list #category-332 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/fitbit.svg"); }
.category-list #category-342 h3 {
  padding-left: 35px; }
.category-list #category-342 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/fluro.svg"); }
.category-list #category-334 h3 {
  padding-left: 35px; }
.category-list #category-334 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/gravity-forms.svg"); }
.category-list #category-350 h3 {
  padding-left: 35px; }
.category-list #category-350 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/keyword-com.svg"); }
.category-list #category-346 h3 {
  padding-left: 35px; }
.category-list #category-346 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/liveheats.svg"); }
.category-list #category-344 h3 {
  padding-left: 35px; }
.category-list #category-344 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/monday-com.svg"); }
.category-list #category-328 h3 {
  padding-left: 35px; }
.category-list #category-328 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/stripe.svg"); }
.category-list #category-330 h3 {
  padding-left: 35px; }
.category-list #category-330 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/typeform.svg"); }
.category-list #category-336 h3 {
  padding-left: 35px; }
.category-list #category-336 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/yahoo-finance.svg"); }
.category-list #category-340 h3 {
  padding-left: 35px; }
.category-list #category-340 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/zalando.svg"); }
.category-list #category-338 h3 {
  padding-left: 35px; }
.category-list #category-338 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/etsy.svg"); }
.category-list #category-319 h3 {
  padding-left: 35px; }
.category-list #category-319 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/instagram-ads.svg"); }
.category-list #category-358 h3 {
  padding-left: 35px; }
.category-list #category-358 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/stripe.svg"); }
.category-list #category-361 h3 {
  padding-left: 35px; }
.category-list #category-361 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/youtube.svg"); }
.category-list #category-375 h3 {
  padding-left: 35px; }
.category-list #category-375 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/calendly.svg"); }
.category-list #category-383 h3 {
  padding-left: 35px; }
.category-list #category-383 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/typeform.svg"); }
.category-list #category-388 h3 {
  padding-left: 35px; }
.category-list #category-388 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-calendar.svg"); }
.category-list #category-412 h3 {
  padding-left: 35px; }
.category-list #category-412 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/microsoft.svg"); }
.category-list #category-417 h3 {
  padding-left: 35px; }
.category-list #category-417 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/quora.svg"); }
.category-list #category-419 h3 {
  padding-left: 35px; }
.category-list #category-419 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/tiktok.svg"); }
.category-list #category-422 h3 {
  padding-left: 35px; }
.category-list #category-422 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/vimeo.svg"); }
.category-list #category-430 h3 {
  padding-left: 35px; }
.category-list #category-430 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-search-console.svg"); }
.category-list #category-435 h3 {
  padding-left: 35px; }
.category-list #category-435 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/intercom.svg"); }
.category-list #category-440 h3 {
  padding-left: 35px; }
.category-list #category-440 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/amazon.svg"); }
.category-list #category-447 h3 {
  padding-left: 35px; }
.category-list #category-447 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/twitter.svg"); }
.category-list #category-453 h3 {
  padding-left: 35px; }
.category-list #category-453 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/postgresql.svg"); }
.category-list #category-460 h3 {
  padding-left: 35px; }
.category-list #category-460 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/redshift.svg"); }
.category-list #category-450 h3 {
  padding-left: 35px; }
.category-list #category-450 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/snapchat.svg"); }
.category-list #category-478 h3 {
  padding-left: 35px; }
.category-list #category-478 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/pinterest.svg"); }
.category-list #category-487 h3 {
  padding-left: 35px; }
.category-list #category-487 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/google-my-business.svg"); }
.category-list #category-495 h3 {
  padding-left: 35px; }
.category-list #category-495 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/apple.svg"); }
.category-list #category-405 h3 {
  padding-left: 35px; }
.category-list #category-405 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/klaviyo.svg"); }
.category-list #category-386 h3 {
  padding-left: 35px; }
.category-list #category-386 h3::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 100%;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%);
    background-image: url("https://static.coupler.io/importers/coupler-io.svg"); }
@media (max-width: 1110px) {
  .collection-category .category-list {
    justify-content: space-evenly; } }
/* generated from scss */
#sidebar {
  padding-left: 30px;
}
#sidebar h3 {
  font-size: 18px;
  color: #030327;
  text-transform: capitalize;
  letter-spacing: -0.02em;
}
#sidebar .nav.nav-list li a {
  font-size: 16px;
  font-weight: 500;
  color: #027aa4;
}
#sidebar .nav.nav-list li.active a {
  position: relative;
  font-weight: 500;
  color: #030327;
}
#sidebar .nav.nav-list li.active .icon-arrow::before {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 9px;
  margin-left: 10px;
  color: #030327;
  content: '';
  background-image: url('/images/helpscout/icon-arrow.svg');
  background-repeat: no-repeat;
  filter: brightness(0) saturate(100%) invert(6%) sepia(17%) saturate(6664%)
    hue-rotate(225deg) brightness(98%) contrast(112%);
  transform: translateY(-50%);
}
#main-content {
  padding: 32px 28px 0 28px;

}
@media (min-width: 768px) {
  #main-content {
    min-height: calc(100vh - 300px);
  }
}
#main-content .contentWrapper #categoryHead .sort select {
  height: 30px;
  font-size: 14px;
  border: 1px solid rgba(135, 156, 165, 0.3);
}
#main-content .related {
  padding: 35px;
  border: 0;
  border-top: 1px solid rgba(135, 156, 165, 0.3);
}
#main-content .related h3 {
  color: #282f3c;
}
#main-content .articleList li a,
#main-content .related li a {
  position: relative;
  padding-left: 23px;
  font-family: 'DM Sans', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 500;
}
#main-content .articleList li:hover a,
#main-content .articleList li a:hover span,
#main-content .related li:hover a {
  text-decoration: none;
}
#main-content .articleList li a i,
#main-content .related li a i {
  display: none;
}
#main-content .articleList li a::before,
#main-content .related li a::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 15px;
  height: 100%;
  content: '';
  background-image: url('/images/helpscout/icon-file.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transform: translateY(-50%);
}
#main-content .contentWrapper {
  padding: 30px 40px;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 4px 45px rgba(5, 117, 158, 0.1);
}
#main-content .contentWrapper #categoryHead h1 {
  margin-bottom: 14px;
  font-size: 36px;
  font-weight: bold;
  line-height: 47px;
}
#main-content .contentWrapper #categoryHead .descrip {
  margin: 0 0 27px;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}
@media (max-width: 768px) {
  #main-content .contentWrapper {
    border-radius: 0;
  }
}
.contentWrapper article#fullArticle h1.title {
  margin: 0 0 14px;
  font-size: 36px;
  font-weight: bold;
  line-height: 47px;
}
.contentWrapper article#fullArticle .printArticle {
  display: none;
}
.contentWrapper article#fullArticle,
.contentWrapper article#fullArticle p,
.contentWrapper article#fullArticle ul,
.contentWrapper article#fullArticle ol,
.contentWrapper article#fullArticle li,
.contentWrapper article#fullArticle div,
.contentWrapper article#fullArticle blockquote,
.contentWrapper article#fullArticle dd,
.contentWrapper article#fullArticle table {
  font-size: 16px;
  color: #000;
}
.contentWrapper article#fullArticle ul,
.contentWrapper article#fullArticle ol {
  margin-left: 0;
  list-style: none;
}
.contentWrapper article#fullArticle ul ul,
.contentWrapper article#fullArticle ul ol,
.contentWrapper article#fullArticle ol ul,
.contentWrapper article#fullArticle ol ol {
    padding-left: 16px;
    margin-bottom: 0;
}
.contentWrapper article#fullArticle ul li::before,
.contentWrapper article#fullArticle ol li::before {
  display: inline-block;
  margin-right: 10px;
  color: #027aa4;
}
.contentWrapper article#fullArticle ul li::before ul,
.contentWrapper article#fullArticle ol li::before ol {
  padding-left: 30px;
  margin-bottom: 0;
}
.contentWrapper article#fullArticle ul li ul li::before {
  content: '\25E6';
}
.contentWrapper article#fullArticle ul li::before {
  content: '\2022';
}
.contentWrapper article#fullArticle ol {
  counter-reset: item;
}
.contentWrapper article#fullArticle ol > li {
  counter-increment: item;
}
.contentWrapper article#fullArticle ol > li::before {
  content: counter(item) ". ";
}
.contentWrapper article#fullArticle ol ol {
  counter-reset: sub-item;
}
.contentWrapper article#fullArticle ol ol > li {
  counter-increment: sub-item;
}
.contentWrapper article#fullArticle ol ol > li::before {
  content: counter(sub-item, lower-alpha) ". ";
}
.contentWrapper article#fullArticle a {
  color: #027aa4;
}
.contentWrapper #fullArticle blockquote {
  border-color: #51a15c;
}
.contentWrapper #fullArticle blockquote p {
  margin: 0;
}
.contentWrapper #fullArticle iframe {
  max-width: 100%;
}
.contentWrapper .articleFoot time {
  display: none;
}
.contentWrapper article#fullArticle h1,
.contentWrapper article#fullArticle h2,
.contentWrapper article#fullArticle h3,
.contentWrapper article.contentWrapper article#fullArticle h4,
#fullArticle h5 {
  color: #282f3c;
}
